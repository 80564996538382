import { createStyles, makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: "white",
            boxShadow: "0px -6px 4px rgba(0, 0, 0, 0.05)",
            height: '100%',
        },

        item: {
            display: 'flex',
            justifyContent: 'center',
        },
        legendActive: {
            color: '#797878',
            fontWeight: 700
        },
        legend: {
            fontSize: '0.8rem',
            color: '#9EAABB',
            fontWeight: 700
        },
        optActive: {
            color: '#FFF',
            fontSize: '1.7rem',
            padding: '2ox',
            backgroundColor: '#F68D31',
            borderRadius: 6
        },
        opt: {
            color: '#9EAABB',
            fontSize: '1.7rem'
        },
        container: {
            height: '100%'
        }
    })
) 