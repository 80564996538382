import { useStyles } from "./response-page-styles";
import { Button, Divider, Grid, Grow, Paper, Slide, Typography } from "@material-ui/core";
import { useResponseContext } from "../../../services/providers";
import { useHistory } from "react-router";
import { isUuid } from 'uuidv4';
export const ResponsePage = () => {
    const classes = useStyles();
    const { image, message, prod } = useResponseContext();
    const history = useHistory();
    const redirectScan = () => {
        return history.goBack()
    }
    if (image === '' || message === '') {
        redirectScan();
    }

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    })

    const pegaDataHora = (date: Date) => {
        const dataHora = new Date(date)
        const hora = dataHora.getHours()
        const minutos = dataHora.getMinutes()
        const getHora = `${hora < 10 ? '0' + hora : hora}:${minutos < 10 ? '0' + minutos : minutos}`
        const getData = Intl.DateTimeFormat("pt-BR").format(dataHora)
        return `${getData} ${getHora}`
    }

    return (
        <div className={classes.mainBox}>
            <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.contemt}>
                    <Grow in={true}>
                        <img src={image} alt="Imagem Resposta" />
                    </Grow>

                </Grid>
                <Grid item xs={12} className={classes.contemt}>
                    <Grow in={true}>
                        <div className={classes.text}>
                            {message}
                        </div>
                    </Grow>
                </Grid>
                {
                    prod?.id !== undefined &&
                    isUuid(prod?.id) &&
                    <>
                        <Grid item xs={12} style={{ marginBottom: "16px" }}>
                            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                                <Paper elevation={1}>
                                    <Grid container style={{ padding: '8px' }}>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" className={classes.textCategoria}>
                                                <span>Cod:</span> {prod.codigo}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography style={{ textAlign: 'right' }} className={classes.textCategoria}>
                                                <span>Seq:</span> {prod.nroItens}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: '8px 0' }}>
                                            <Typography variant="body2" className={classes.titleObject}>
                                                Produto
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, color: '#5F6368' }}>
                                                {prod.nome}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" className={classes.titleObject}>
                                                Cupom
                                            </Typography>
                                            <Typography className={classes.valueObject}>
                                                {prod.nroCupom} - {prod.tpCupom}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body2" className={classes.titleObject}>
                                                Qtd total
                                            </Typography>
                                            <Typography className={classes.valueObject}>
                                                {prod.quantidade}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body2" className={classes.titleObject}>
                                                Valor nota
                                            </Typography>
                                            <Typography className={classes.valueObject}>
                                                {formatter.format(prod.valor)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Slide>
                        </Grid>
                        <Grid item xs={12}>
                            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                                <Paper elevation={1} style={{ padding: '8px' }}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <span className={classes.setor}>
                                                {prod.terminal}
                                            </span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" style={{ textAlign: 'right' }} className={classes.textCategoria}>
                                                <span>Funcionário:</span> {prod.usuario}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider style={{ margin: '.6rem 0' }} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" className={classes.titleObject}>
                                                CNPJ
                                            </Typography>
                                            <Typography className={classes.valueObject}>
                                                {prod.cnpj}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" className={classes.titleObject}>
                                                Emissão
                                            </Typography>
                                            <Typography className={classes.valueObject}>
                                                {pegaDataHora(new Date(prod.data))}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" className={classes.titleObject}>
                                                Leitura/Baixa
                                            </Typography>
                                            <Typography className={classes.valueObject}>
                                                {pegaDataHora(new Date(prod.dataLeitura))}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ paddingTop: 8 }}>
                                            <Typography variant="body2" className={classes.titleObject}>
                                                ID
                                            </Typography>
                                            <Typography className={classes.valueObject}>
                                                {prod.id}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Slide>
                        </Grid>
                    </>
                }
                <Grid item xs={12} className={classes.footerBox}>
                    <Grow in={true}>
                        <Button variant="contained" onClick={() => redirectScan()} fullWidth className={classes.button}>Continuar</Button>
                    </Grow>
                </Grid>
            </Grid>
        </div >
    )
}