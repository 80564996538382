import { useQrCode } from "../../../data/api/baixas/qrcode/qrcode-service";
import { QrCodeApiModel } from "../../../model/api/QrCode/QrCodeApiModel";
import { Button, Divider, Grid, Grow, Paper, Typography } from "@material-ui/core";
import { useStyles } from "./scan-fisico-page-styles";
import QrReader from 'react-qr-reader'
import { Nav } from '../../../views/components/nav'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useResponseContext } from "../../../services/providers";
import Ok from '../../../assets/img/ok.svg';
import Err from '../../../assets/img/err.svg';
import { useHistory } from "react-router-dom";
import { ProdModel } from "../../../model/api/Prod/prod-model";
import { useEffect, useRef, useState } from "react";
import { CircularLoading } from "../../components/utils/circular-loading";
import { KeyboardString } from "../../components/keyboard/keyboard-string/keyboard-string";
import useEventListener from '@use-it/event-listener';
import { useSessaoAtual } from "../../../services/providers/sessao-atual-provider/sessao-atual-provider";
import { stringNumeros } from "../../../utils/string-numeros";


export const ScanFisicoPage = () => {
    const classes = useStyles();
    const { setImage, setMessage, setProd } = useResponseContext();
    const { ScanQrCode, carregando } = useQrCode();
    const clickButton = useRef<HTMLButtonElement>(null)
    const history = useHistory();
    const { getData } = useSessaoAtual();

    const [digitado, setDigitado] = useState<boolean>(false)
    const [codigo, setCodigo] = useState<any>()

    useEventListener("keydown", (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            clickButton.current?.click()
        }
    })

    const handleScan = async (data: any) => {

        if (data) {
            const rplc = data.replaceAll(";", "/")
            const res = await ScanQrCode(new QrCodeApiModel(rplc, stringNumeros(String(getData()?.cnpj)), getData()?.nomeTerminal, getData()?.nomeUsuario))
            if (res.erro) {
                if (res.erro.title === 'Entrega já registrada!') {
                    setCodigo('')
                    setMessage(res.erro.title);
                    setImage(Err);
                    let model: ProdModel = res.erro.body;
                    setProd(model)
                    history.push('/response')
                    return;
                }
                toast.error(res.erro.message);
                setCodigo('')
                return;
            }
            setMessage("Baixa confirmada com sucesso!");
            setImage(Ok);
            let model: ProdModel = res.resultado?.data;
            setProd(model)
            setCodigo('')
            history.push('/response')
            return;
        }
    }

    const pegarValor = (value: any) => {
        if (value !== null) {
            setCodigo(value)
        }
    }

    const handleError = (err: any) => {
        setCodigo('')
        console.error(err)
    }

    useEffect(() => {
        if (codigo === undefined) {
            setCodigo('')
        }
    }, [codigo])

    return (
        <>
            <div className={classes.root}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container className={classes.container}>
                    <Grid item xs={12} className={classes.titleBox}>
                        <Grow in>
                            <Typography variant="h6" style={{ margin: '1rem 0' }}>
                                Leitor QRCode - Físico
                            </Typography>
                        </Grow>

                        <Grow in>
                            <Paper style={{ backgroundColor: '#EBEBEB', width: '100%' }}>
                                <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '8px', height: '250px' }}>
                                    <Grid item>
                                        <Typography variant="h6" style={{ fontWeight: 600 }}>
                                            Código lido:
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ width: '100%' }}>
                                        <Divider variant="middle" style={{ background: '#F68D31' }} />
                                    </Grid>
                                    <Grid item style={{ wordBreak: 'break-all', padding: '16px', textAlign: 'center' }}>
                                        {codigo ? codigo.replaceAll(";", "/") : 'Aproxime o leitor físico do código ou digite-o'}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grow>
                        <Grow in>
                            <Button variant="contained" ref={clickButton} fullWidth onClick={() => handleScan(codigo)} disabled={!codigo && true} className={classes.button}>Confirmar</Button>
                        </Grow>
                    </Grid>
                </Grid>
            </div>
            <KeyboardString
                digitado={digitado}
                vDigitado={codigo}
                setvDigitado={setCodigo}
                setDigitado={setDigitado}
            />
            <QrReader
                delay={1000}
                onError={handleError}
                onScan={pegarValor}
                style={{ display: 'none' }}
            />
            <div className={classes.nav}>
                <Nav active="scanFisico" />
            </div>
        </>
    )
}
