import { createContext, useContext, useState } from "react";
import { ProdModel } from '../../../model/api/Prod/prod-model'

interface ResponseContextValues {
    image: string,
    setImage: (url: string) => void,
    message: string,
    setMessage: (message: string) => void,
    clearAll: () => void,
    prod: ProdModel | undefined,
    setProd: (model: ProdModel) => void,
}

const ResponseContext = createContext<ResponseContextValues>({
    image: '',
    setImage: (url: string) => { return true },
    message: '',
    setMessage: (message: string) => { return true },
    prod: undefined,
    clearAll: () => { return true },
    setProd: (model: ProdModel) => { return true },
})
export interface ResponseProviderProps {
    children: React.ReactNode;
}
export const useResponseContext = () => {
    return useContext(ResponseContext);
}
export const ResponseProvider = ({ children }: ResponseProviderProps) => {
    const [image, setImage] = useState('');
    const [prod, setProd] = useState(new ProdModel());
    const [message, setMessage] = useState('');
    const clearAll = () => {
        setImage('');
        setMessage('');
    }

    return (
        <ResponseContext.Provider value={{
            image,
            setImage,
            message,
            setMessage,
            clearAll,
            prod,
            setProd,
        }}>
            {children}
        </ResponseContext.Provider>
    )
}
