import { useQrCode } from "../../../data/api/baixas/qrcode/qrcode-service";
import { QrCodeApiModel } from "../../../model/api/QrCode/QrCodeApiModel";
import { Grid, Grow, Typography } from "@material-ui/core";
import { useStyles } from "./scan-page-styles";
import QrReader from 'react-qr-reader'
import { Nav } from '../../../views/components/nav'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useResponseContext } from "../../../services/providers";
import Ok from '../../../assets/img/ok.svg';
import Err from '../../../assets/img/err.svg';
import { useHistory } from "react-router-dom";
import { ProdModel } from "../../../model/api/Prod/prod-model";
import { CircularLoading } from "../../components/utils/circular-loading";
import { useSessaoAtual } from "../../../services/providers/sessao-atual-provider/sessao-atual-provider";
import { stringNumeros } from "../../../utils/string-numeros";

export const ScanPage = () => {
    const classes = useStyles();
    const { setImage, setMessage, setProd } = useResponseContext();
    const { ScanQrCode, carregando } = useQrCode();
    const history = useHistory();
    const { getData } = useSessaoAtual();

    const handleScan = async (data: any) => {

        if (data) {
            const res = await ScanQrCode(new QrCodeApiModel(data, stringNumeros(String(getData()?.cnpj)), getData()?.nomeTerminal, getData()?.nomeUsuario))
            if (res.erro) {
                if (res.erro.title === 'Entrega já registrada!') {
                    setMessage(res.erro.title);
                    setImage(Err);
                    const model: ProdModel = res.erro.body;
                    setProd(model)
                    history.push('/response')
                    return;
                }
                toast.error(res.erro.message);
                return;
            }
            setMessage("Baixa confirmada!");
            setImage(Ok);
            let model: ProdModel = res.resultado?.data;
            setProd(model)
            history.push('/response')
            return;
        }
    }

    const handleError = (err: any) => {
        console.error(err)
    }

    return (
        <>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <div className={classes.root}>
                <Grid container className={classes.container}>
                    <Grid item className={classes.titleBox}>
                        <Grow in>
                            <Typography variant="h6">
                                Leitor QRCode - Câmera
                            </Typography>
                        </Grow>
                        <Grow in>
                            <Typography variant="body1" style={{ textAlign: 'center', padding: '1rem 0' }}>
                                Aproxime a câmera no leitor para dar baixa na entrega
                            </Typography>
                        </Grow>
                        <div className={classes.qrcode}>
                            <Grow in>
                                <QrReader
                                    className={classes.qr}
                                    delay={1000}
                                    onError={handleError}
                                    onScan={handleScan}
                                />
                            </Grow>
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.nav}>
                    <Nav active="scanCamera" />
                </div>
            </div>
        </>
    )
}
