import { createStyles, makeStyles } from "@material-ui/core"
import Background from '../../../assets/img/Background.svg';
export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: `url(${Background})`,
            height: '100vh',
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'center'
        },
        content: {
            maxWidth: '650px',
        },
        title: {
            color: '#F68D31',
            height: '220px',
            fontSize: '35px',
            fontWeight: 700,
            padding: '35px',
            display: 'flex',
            alignContent: 'flex-end',
            alignItems: 'flex-end'
        },
        button: {
            backgroundColor: '#F68D31',
            marginTop: '150px',
            textTransform: 'capitalize',
            color: 'white',
            fontWeight: 700,
            boxShadow: '0.964912px 2.82121px 5.78947px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            "&:hover": {
                backgroundColor: '#E89247'
            }
        },
        text: {
            padding: '35px',
            color: '#8D8D8D',
            paddingTop: '10px',
            paddingBottom: '10px',
            fontSize: '18px',
            textAlign: 'justify',
            fontWeight: 600,
            lineHeight: '120%'
        },
        buttonWrapper: {
            padding: '35px',
        }
    })
)