
import { useMemo } from 'react';
import * as Yup from 'yup';
import { useValidationYupDocumento } from '../../components/form-validations/yup/documento';

export const useFormPasswordPageValidation = () => {

  const { documentoYup } = useValidationYupDocumento(true)

  const FormPasswordPageValidation = useMemo(() => {
    return Yup.object().shape({
      cnpj: documentoYup('CNPJ'),
    });
  }, [documentoYup])

  return {
    FormPasswordPageValidation,
  }
}

