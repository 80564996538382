import React, { createContext, useCallback, useContext, useEffect, useRef } from "react"
import { BaixasStorageKeys, useBaixasStorage } from "../../use-cases/baixas-storage"
import { useAuth } from '../../../data/api/baixas/auth/auth-service'
import { AuthModel } from "../../../model/api/Auth/auth-model"
import { useBaixasToken } from "../../use-cases/baixas-token"
import { senhaSync } from '../../../views/components/utils/senhaSync'
import { UserDadosModel } from "../../../model/api/UserDados"

export interface SessaoAtualContextValues {
    Login: (nomeUsuario: string, senha: string) => Promise<void>,
    Logout: () => void,
    changeUserPassword: (nomeTerminal: string, cnpj: string, senha: string, mudouSenha: boolean) => void
    FirstAccess: () => boolean,
    IsValidSession: () => boolean,
    getData: () => UserDadosModel | undefined;
}
const SessaoAtualContext = createContext<SessaoAtualContextValues>({
    Login: (nomeUsuario: string, senha: string) => { return new Promise<void>(() => true) },
    changeUserPassword: (nomeTerminal: string, cnpj: string, senha: string, mudouSenha: boolean) => { },
    IsValidSession: () => { return false },
    FirstAccess: () => { return false },
    Logout: () => { },
    getData: () => undefined,
})
interface Props {
    children: React.ReactNode;
}
export const SessaoAtualProvider = ({ children }: Props) => {
    const { getRegister, delRegister, exists, setRegister } = useBaixasStorage();
    const { isTokenValid, decodeToken } = useBaixasToken()
    const { obterSenhaSyncAtual } = senhaSync();
    const { Authenticate } = useAuth()
    const data = useRef<UserDadosModel | undefined>(undefined);

    const Logout = () => {
        delRegister(BaixasStorageKeys.AuthToken, false)
    }


    const getData = React.useCallback((): UserDadosModel | undefined => {
        return data.current;
    }, []);

    const objetoData = useCallback(() => {
        const nomeUsuario: string = getRegister(BaixasStorageKeys.NomeUsuario, false)
        const nomeTerminal: string = getRegister(BaixasStorageKeys.NomeTerminal, false)
        const cnpj: string = getRegister(BaixasStorageKeys.cnpj, false)

        const obj = {
            nomeUsuario: exists(BaixasStorageKeys.NomeUsuario, false) ? nomeUsuario : '',
            nomeTerminal: exists(BaixasStorageKeys.NomeTerminal, false) ? nomeTerminal : '',
            cnpj: exists(BaixasStorageKeys.cnpj, false) ? cnpj : '',
        }
        if (!data.current) {
            data.current = obj
        }
    }, [exists, getRegister])

    useEffect(() => {
        getData()
        objetoData()
    }, [objetoData, getData])

    const Login = useCallback(async (nomeUsuario: string, senha: string) => {
        if (senha === getRegister(BaixasStorageKeys.UserPassword, false) || senha === obterSenhaSyncAtual()) {
            let res = await (await Authenticate(new AuthModel("@@ophd0202$sig-AuthPass")));
            if (res.erro) {
                throw new Error(res.erro)
            }
            setRegister(BaixasStorageKeys.NomeUsuario, nomeUsuario, false)
            setRegister(BaixasStorageKeys.AuthToken, res.resultado?.data, false)
            objetoData()
        }
        else {
            throw new Error('Senha incorreta');
        }
    }, [Authenticate, getRegister, obterSenhaSyncAtual, setRegister, objetoData])

    const FirstAccess = useCallback((): boolean => {
        return !exists(BaixasStorageKeys.UserPassword, false)
    }, [exists])

    const changeUserPassword = useCallback(
        (nomeTerminal: string, cnpj: string, senha: string, mudouSenha: boolean) => {
            try {
                if (FirstAccess()) {
                    setRegister(BaixasStorageKeys.NomeTerminal, nomeTerminal, false);
                    setRegister(BaixasStorageKeys.cnpj, cnpj, false);
                    setRegister(BaixasStorageKeys.UserPassword, senha, false);
                    objetoData()
                }
                else if (mudouSenha) {
                    setRegister(BaixasStorageKeys.UserPassword, senha, false);
                    setRegister(BaixasStorageKeys.NomeTerminal, nomeTerminal, false);
                    setRegister(BaixasStorageKeys.cnpj, cnpj, false);
                    objetoData()
                } else {
                    setRegister(BaixasStorageKeys.NomeTerminal, nomeTerminal, false);
                    setRegister(BaixasStorageKeys.cnpj, cnpj, false);
                    objetoData()
                }
            } catch (e: any) {
                console.log(e.message)
            }
        }, [FirstAccess, setRegister, objetoData]
    )

    const IsValidSession = useCallback((): boolean => {
        return isTokenValid(decodeToken(getRegister(BaixasStorageKeys.AuthToken, false)))
    }, [decodeToken, getRegister, isTokenValid])

    return (
        <SessaoAtualContext.Provider value={{
            Login,
            Logout,
            changeUserPassword,
            IsValidSession,
            FirstAccess,
            getData
        }}>
            {children}
        </SessaoAtualContext.Provider>
    )
}

export const useSessaoAtual = () => {
    return useContext(SessaoAtualContext);
}