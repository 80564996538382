import { createStyles, makeStyles } from "@material-ui/core"
export const useStyles = makeStyles(() =>
    createStyles({
        text: {
            color: '#F68D31',
            textAlign: 'center',
            marginTop: '50px',
            marginBottom: '25px',
            fontSize: '28px',
            fontWeight: 600

        },
        contemt: {
            display: 'flex',
            justifyContent: 'center',
        },
        footer: {
        },
        footerBox: {
            width: '100%',
            justifyContent: 'center',
            display: 'flex'
        },
        iten: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        lineText: {
            marginTop: '15px',
        },
        mainBox: {
            justifyContent: "center",
            display: 'flex',
            marginTop: '50px'
        },
        button: {
            backgroundColor: '#F68D31',
            color: 'white',
            fontWeight: 600,

            marginTop: '20px',
            borderRadius: '5.70314px',
            "&:hover": {
                backgroundColor: '#E6A973',
            }
        },
        textCategoria: {
            fontWeight: 600,
            color: '#5F6368',
            fontSize: '14px',
            "& span": {
                color: '#F68D31'
            },
        },
        titleObject: {
            fontWeight: 600,
            fontSize: 10,
            color: '#FF9F4A'
        },
        valueObject: {
            fontSize: '12px',
            fontWeight: 600,
            color: '#5F6368'
        },
        container: {
            maxWidth: 650,
            padding: 8
        },
        setor: {
            backgroundColor: '#FF9F4A',
            padding: '4px 10px',
            fontSize: '.8rem',
            fontWeight: 600,
            color: '#FFF',
            borderRadius: '5px',
        },
    })
) 