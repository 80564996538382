import {
    BrowserRouter as Router,
    Switch,
} from "react-router-dom";
import { LoginPage } from '../../src/views/pages/login-page'
import { ResponseProvider } from "../services/providers";
import { FirstAccessPage } from '../views/pages/first-access-page/first-access-page'
import { PasswordPage } from '../views/pages/password-page/password-page'
import { RouterMain } from './components/router-main/router-main'
import { PrivateRoute } from './components/private-route'
export const RouterApp = () => {
    return (
        <Router>
            <Switch>
                <ResponseProvider>
                    <PrivateRoute exact path="/" isPrivate={false} checkIsInactive={true}>
                        <LoginPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/firstaccess" isPrivate={false} checkIsInactive={true}>
                        <FirstAccessPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/escolhersenha" isPrivate={false} checkIsInactive={true}>
                        <PasswordPage />
                    </PrivateRoute>
                    <PrivateRoute isPrivate={true} checkIsInactive={false}>
                        <RouterMain />
                    </PrivateRoute>
                </ResponseProvider>
            </Switch>
        </Router>
    )
}