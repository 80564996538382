import { createStyles, makeStyles } from "@material-ui/core"
import Background from '../../../assets/img/Background.svg';
export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: `url(${Background})`,
            height: '100%',
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'center'
        },
        content: {
            maxWidth: '650px',
        },
        toast: {
            width: '100%',
            padding: '25px',
            marginTop: '10px',
            textAlign: 'center'
        },
        title: {
            color: '#F68D31',
            marginTop: 50,
            fontSize: '35px',
            fontWeight: 700,
            padding: '35px',
            display: 'flex',
            alignContent: 'flex-end',
            alignItems: 'flex-end'
        },
        back: {
            position: 'absolute',
            marginToi: '10px',
        },
        backIcon: {
            color: '#F68D31',
            fontSize: '60px'
        },
        buttonWrapper: {
            padding: '35px',
        },
        button: {
            backgroundColor: '#F68D31',
            marginTop: '25px',
            textTransform: 'capitalize',
            color: 'white',
            fontWeight: 700,
            boxShadow: '0.964912px 2.82121px 5.78947px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            "&:hover": {
                backgroundColor: '#E89247'
            }
        },
        text: {
            padding: '35px',
            color: '#8D8D8D',
            paddingTop: '0px',
            paddingBottom: '10px',
            marginBottom: '30px',
            fontSize: '18px',
            textAlign: 'justify',
            fontWeight: 500,
            lineHeight: '120%'
        },
        input: {
            padding: '32px',
            paddingTop: '15px',
            paddingBottom: '15px',
        },
        startIcon: {
            color: '#F68D31',
            fontSize: '25px',
            fontWeight: 700,
            padding: '7px'
        },
        box: {
            "&::before": {
                borderBottom: '2px solid #DCDCDC'
            },
            "&::after": {
                borderBottom: '2px solid #F68D31'
            },
        },
        field: {
            color: '#000',
        },
    })
)