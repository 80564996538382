import { useCallback } from "react";
import { useApiBase } from '../base/api-baixas-base'

export const useGetHistorico = () => {
    const { invocarApi, ...props } = useApiBase();

    const getHistorico = useCallback(
        (cnpj: string, page?: Number, data?: string) => {

            let queryPage = "";
            if (page) {
                queryPage = "page=" + page.toString();
            }
            let queryFinal = `${queryPage?.length > 0 ? "?" : ""}${queryPage}${data}`;
            return invocarApi({
                url: `/api/baixa/${cnpj}${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true,
            })
        },
        [invocarApi],
    )
    return {
        getHistorico,
        ...props
    }
}
