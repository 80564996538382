import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { SessaoAtualProvider } from './services/providers/sessao-atual-provider/sessao-atual-provider';
import { Snackbar, Button } from '@material-ui/core';

import './assets/css/circular-loading.css'
import { ToastContainer } from 'react-toastify';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


ReactDOM.render(
  <React.StrictMode>
    <SessaoAtualProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        limit={1}
      />
      <App />
    </SessaoAtualProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  serviceWorkerRegistration.register({ onUpdate: onSWUpdate });

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };

  return (
    <Snackbar
      open={showReload}
      message="Uma nova versão está disponível!"
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={reloadPage}
        >
          Atualizar
        </Button>
      }
    />
  );
}

export default ServiceWorkerWrapper;
