import { Grid, IconButton } from "@material-ui/core";
import { useStyles } from "./nav-styles";
import { RiCamera2Fill, RiQrCodeFill, RiToolsFill } from 'react-icons/ri'
import { useHistory } from "react-router";

export const Nav = (props: any) => {
    const classes = useStyles()
    const history = useHistory()
    const redirectToSettings = () => history.push("/settings");
    const redirectToScan = () => history.push("/scan-camera");
    const redirectToScanFisico = () => history.push("/scan-fisico");
    return (
        <div className={classes.root}>
            <Grid container className={classes.container}>
                <Grid item xs={4} className={classes.item}>
                    <Grid container>
                        <Grid item xs={12} className={classes.item}>
                            <IconButton onClick={() => redirectToScan()} >
                                <RiCamera2Fill className={props.active === 'scanCamera' ? classes.optActive : classes.opt} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} className={classes.item}>
                            <div className={classes.legend}>Leitor Câmera</div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    <Grid container>
                        <Grid item xs={12} className={classes.item}>
                            <IconButton onClick={() => redirectToScanFisico()} >
                                <RiQrCodeFill className={props.active === 'scanFisico' ? classes.optActive : classes.opt} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} className={classes.item}>
                            <div className={classes.legend}>Leitor Fisíco</div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    <Grid container>
                        <Grid item xs={12} className={classes.item}>
                            <IconButton aria-label="delete" onClick={() => redirectToSettings()} >
                                <RiToolsFill className={props.active === 'config' ? classes.optActive : classes.opt} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} className={classes.item}>
                            <div className={classes.legend}>Configurações</div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}