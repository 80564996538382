import { useCallback } from "react";
import { useApiBase } from '../base/api-baixas-base'
import { AuthModel } from '../../../../model/api/Auth/auth-model'

export const useAuth = () => {
    const { invocarApi } = useApiBase();
    const Authenticate = useCallback(
        (model : AuthModel) => {
            return invocarApi({
                url: '/api/auth/integrar',
                method: 'POST',
                enviarTokenUsuario: false,

                data: model
            })
        },
        [invocarApi],
    )
    return {                
        Authenticate
    }
}
