import { createStyles, makeStyles } from "@material-ui/core"
import Background from '../../../assets/img/Background.svg';

export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            background: `url(${Background})`,
            width: '100%',
            minHeight: '100%',
            display: 'flex',
            justifyContent: 'center'
        },
        container: {
            padding: '0 1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '650px',
            [theme.breakpoints.up('sm')]: {
                height: "100vh"
            },
            [theme.breakpoints.down('xs')]: {
                height: "100%",
                marginTop: 100
            },
        },
        titleBox: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        title: {
            fontSize: '22px',
            color: '#5F6368',
            textAlign: 'center',
            lineHeight: '130%',
            fontWeight: 600,
            padding: '10px'
        },
        qrcodebox: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        wrapper: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        footer: {
            maxWidth: '400px',
            textAlign: 'center'
        },
        qr: {
        },
        text: {
            color: '#8F8F8F',
            fontWeight: 500
        },
        qrCodeText: {
            color: '#F68D31',
            width: '400px',
            fontWeight: 500
        },
        button: {
            backgroundColor: '#F68D31',
            color: 'white',
            fontWeight: 600,
            padding: ' 8px',
            marginTop: '20px',
            borderRadius: '5.70314px',
            "&:hover": {
                backgroundColor: '#E6A973',
            }
        },
        footerBox: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '50px'

        },
        nav: {
            position: 'fixed',
            bottom: 0,
            height: '75px',
            width: '100%',
        }
    })
)