import { useEffect, useState, useCallback } from "react";
import { Grid, TextField } from "@material-ui/core";
import { useStyles } from "./historico-list-styles";
import { HistoricoListData } from "./historico-list-data";
import { Paginacao } from "../../../components/paginacao/paginacao";
import { useGetHistorico } from "../../../../data/api/baixas/historico/get-historico";
import { useSessaoAtual } from "../../../../services/providers/sessao-atual-provider/sessao-atual-provider";
import { HistoricoModel } from "../../../../model/api/Historico/historico-model";
import { stringNumeros } from "../../../../utils/string-numeros";
import { SelectSaurus } from "../../../components/selects/select-saurus/select-saurus";
import { KeyValueModel } from "../../../../model/api/utils";
import { CircularLoading } from "../../../components/utils/circular-loading";

export const HistoricoList = (() => {

  const classes = useStyles();
  const [queryStatus, setQueryStatus] = useState({ page: 1, totalPages: 0, totalResults: 0, list: Array<HistoricoModel>() });
  const { getHistorico, carregando } = useGetHistorico()
  const { getData } = useSessaoAtual()
  const [statusPesquisar, setStatusPesquisar] = useState<any>(0);
  const data = new Date()

  const fillResult = useCallback(async (page: number, totalPages: number, totalResults: number, list: Array<HistoricoModel>) => {
    setQueryStatus({ page: page, list: list, totalResults: totalResults, totalPages: totalPages });
  }, []);

  const dataFormatada = useCallback((val) => {
    const data = new Date(val)
    const dia = data.getDate() + 1
    const mes = data.getMonth() + 1
    const ano = data.getFullYear()
    return `${ano}-${mes.toString().length === 1 ? '0' + mes : mes}-${dia.toString().length === 1 ? '0' + dia : dia}`
  }, [])

  const [dataInicial, setDataInicial] = useState<any>(dataFormatada(data.setDate(data.getDate() - 1)))
  const [dataFinal, setDataFinal] = useState<any>(dataFormatada(new Date()))

  const query = `${dataInicial !== undefined ?
    `?DataInicial=${dataFormatada(dataInicial)}T00:00:00` : ''}${dataFinal !== undefined ?
      ('&DataFinal=' + dataFormatada(dataFinal)) + 'T23:59:59.183496' : ''}`

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await getHistorico(stringNumeros(getData()?.cnpj || ''), newPage, query);

        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages && res.resultado?.data?.totalResults > 0) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

      } catch (e: any) {
        console.error("error", e.message);
      }
    },
    [fillResult, getData, getHistorico, query]
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  useEffect(() => {
    setTimeout(() => {
      search(0)
    }, 100)
  }, [search]);

  const unique = Array.from(
    new Set<string>(queryStatus.list.map((item) => item.terminal)),
  );
  const mock: Array<KeyValueModel> = unique.map((item, index) => {
    return new KeyValueModel((index + 1).toString(), item)
  })
  mock.push(new KeyValueModel('0', 'Todos'))


  return (
    <>
      {carregando && (
        <CircularLoading tipo="FULLSIZED" />
      )}
      <div className={classes.defaultContainer}>
        <Grid container >
          {queryStatus.list.length !== 0 &&
            <SelectSaurus
              style={{
                height: "35px",
                backgroundColor: 'rgb(142 142 147 / 16%)',
                fontWeight: 500,
                color: '#696969'
              }}
              fullWidth={true}
              variant="outlined"
              conteudo={mock.sort((a, b) => a.Key - b.Key)}
              allowSubmit
              onChange={e => {
                if (e) {
                  setStatusPesquisar(e.target.value)
                }
              }}
              value={statusPesquisar}
            />
          }
          <Grid item xs={6} style={{ padding: '8px 8px 0 0' }}>
            <TextField
              id="date"
              type="date"
              label="Data Inicial"
              variant="filled"
              value={dataInicial}
              style={{ width: '100%' }}
              onChange={e => setDataInicial(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} style={{ padding: '8px 0 0 8px' }}>
            <TextField
              id="date"
              type="date"
              label="Data Final"
              variant="filled"
              value={dataFinal}
              style={{ width: '100%' }}
              onChange={e => setDataFinal(e.target.value)}
            />
          </Grid>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={statusPesquisar !== '0' && statusPesquisar
              ? queryStatus.list.filter(item => item.terminal === mock.filter(item => item.Key === statusPesquisar).map(item => item.Value)[0]).length :
              queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <HistoricoListData
              carregando={carregando}
              list={!statusPesquisar || statusPesquisar === '0'
                ? queryStatus.list
                : queryStatus.list.filter(item => item.terminal === mock.filter(item => item.Key === statusPesquisar).map(item => item.Value)[0])}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
);
