import { useCallback } from "react"
export enum BaixasStorageKeys {
    AuthToken,
    NomeTerminal,
    cnpj,
    NomeUsuario,
    UserPassword,
    Historico
}
export const useBaixasStorage = () => {

    const getRegister = useCallback(
        (key: BaixasStorageKeys, session: boolean = false) => {
            return JSON.parse((session ? sessionStorage.getItem(BaixasStorageKeys[key].toString()) : localStorage.getItem(BaixasStorageKeys[key].toString())) || '{}')
        }, [])

    const exists = useCallback(
        (key: BaixasStorageKeys, session: boolean = false) => {
            if (session)
                return Boolean(sessionStorage.getItem(BaixasStorageKeys[key].toString()))
            return Boolean(localStorage.getItem(BaixasStorageKeys[key].toString()))
        },
        [],
    )
    const setRegister = useCallback(
        (key: BaixasStorageKeys, obj: any, session: boolean = false) => {
            if (session)
                sessionStorage.setItem(BaixasStorageKeys[key].toString(), JSON.stringify(obj));
            else
                localStorage.setItem(BaixasStorageKeys[key].toString(), JSON.stringify(obj))
        },
        [],
    )

    const delRegister = useCallback(
        (key: BaixasStorageKeys, session: boolean = false) => {
            if (session)
                sessionStorage.removeItem(BaixasStorageKeys[key].toString());
            else
                localStorage.removeItem(BaixasStorageKeys[key].toString());
        },
        [],
    )
    return {
        getRegister,
        exists,
        setRegister,
        delRegister
    }
}