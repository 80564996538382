export class ProdModel {
    constructor(
        public id: string = '',
        public codigo: number = 0,
        public usuario: string = '',
        public cnpj: string = '',
        public nome: string = '',
        public valor: number = 0,
        public quantidade: number = 0,
        public nroItens: number = 0,
        public data: Date = new Date(),
        public nroCupom: number = 0,
        public tpCupom: string = '',
        public terminal: string = '',
        public dataLeitura: Date = new Date()
    ) { }
}