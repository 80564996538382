import { createStyles, makeStyles } from "@material-ui/core"
import Background from '../../../assets/img/Background.svg';
export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: `url(${Background})`,
            height: '100vh',
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'center'
        },
        content: {
            maxWidth: '650px',
            padding: '16px'
        },
        toast: {
            width: '100%',
            padding: '25px',
            marginTop: '10px',
            textAlign: 'center'
        },
        title: {
            color: '#F68D31',
            fontSize: '30px',
            marginBottom: 16,
            fontWeight: 700,
            display: 'flex',
            alignContent: 'flex-end',
            alignItems: 'flex-end'
        },
        back: {
            position: 'absolute',
            marginTop: '10px',
        },
        setor: {
            backgroundColor: '#FF9F4A',
            padding: '4px 10px',
            fontSize: '.8rem',
            fontWeight: 600,
            color: '#FFF',
            borderRadius: '5px'
        },
        subtitulosPaper: {
            color: "#FF9F4A",
            fontWeight: 600
        },
        textPaper: {
            color: '#696969',
            fontSize: 12,
            fontWeight: 600
        },
        backIcon: {
            color: '#F68D31',
            fontSize: '60px'
        },
        buttonWrapper: {
            padding: '35px',
            marginTop: '100px'
        },
        button: {
            backgroundColor: '#F68D31',
            marginTop: '25px',
            textTransform: 'capitalize',
            color: 'white',
            fontWeight: 700,
            boxShadow: '0.964912px 2.82121px 5.78947px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            "&:hover": {
                backgroundColor: '#E89247'
            }
        },
        text: {
            color: '#8D8D8D',
            fontSize: '18px',
            marginBottom: 30,
            fontWeight: 500,
            lineHeight: '120%'
        },
        startIcon: {
            color: '#F68D31',
            fontSize: '25px',
            fontWeight: 700,
            padding: '7px'
        },
        box: {
            "&::before": {
                borderBottom: '2px solid #DCDCDC'
            },
            "&::after": {
                borderBottom: '2px solid #F68D31'
            },
        },
        field: {
            color: '#B7B7B7',
        },
    })
)