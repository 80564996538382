import { Typography, Grid, Paper, Divider } from '@material-ui/core';
import { HistoricoCardProps } from './historico-card-props';
import { useStyles } from './historico-card-styles'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';

export const HistoricoCard = ({
  model,
}: HistoricoCardProps) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState<string | false>(false);

  const pegaDataHora = (date: Date) => {
    const dataHora = new Date(date)
    const hora = dataHora.getHours()
    const minutos = dataHora.getMinutes()
    const getHora = `${hora < 10 ? '0' + hora : hora}:${minutos < 10 ? '0' + minutos : minutos}`
    const getData = Intl.DateTimeFormat("pt-BR").format(dataHora)
    return `${getData} ${getHora}`
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Paper elevation={4} style={{ margin: '8px 0' }}>
      <Grid container style={{ padding: '8px' }}>
        <Grid item xs={12}>
          <span className={classes.setor}>
            {model.terminal}
          </span>
          <Divider style={{ margin: '.6rem 0' }} />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="caption" className={classes.subtitulosPaper}>
            Produto
          </Typography>
          <Typography variant="body2" className={classes.textPaper}>
            {model.nome}
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <Typography variant="caption" className={classes.subtitulosPaper}>
              Emissão
            </Typography>
            <Typography variant="body2" className={classes.textPaper}>
              {pegaDataHora(new Date(model.data))}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="caption" className={classes.subtitulosPaper}>
            Cupom
          </Typography>
          <Typography variant="body2" className={classes.textPaper}>
            {model.nroCupom} - {model.tpCupom}
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <Typography variant="caption" className={classes.subtitulosPaper}>
              Qtd total
            </Typography>
            <Typography variant="body2" className={classes.textPaper}>
              {model.quantidade}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <Typography variant="caption" className={classes.subtitulosPaper}>
              Valor nota
            </Typography>
            <Typography variant="body2" className={classes.textPaper}>
              {formatter.format(model.valor)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <Typography variant="caption" className={classes.subtitulosPaper}>
              Seq
            </Typography>
            <Typography variant="body2" className={classes.textPaper}>
              {model.nroItens}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container style={{ padding: '0 0 8px 0' }}>
        <Grid item xs={12}>
          <Accordion style={{ boxShadow: 'none' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: '#FF9F4A', fontWeight: '700', fontSize: '2rem' }} />} style={{ padding: '0 8px' }}
            >
              <Typography style={{ fontWeight: 600, color: '#5F6368' }}>{!expanded ? 'Mais informações' : 'Menos informações'}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0 8px' }}>
              <Grid container>
                <Grid item xs={10} style={{ paddingBottom: 8 }}>
                  <div>
                    <Typography variant="caption" className={classes.subtitulosPaper}>
                      ID
                    </Typography>
                    <Typography variant="body2" className={classes.textPaper}>
                      {model.id}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div>
                    <Typography variant="caption" className={classes.subtitulosPaper}>
                      Código
                    </Typography>
                    <Typography variant="body2" className={classes.textPaper}>
                      {model.codigo}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    <Typography variant="caption" className={classes.subtitulosPaper}>
                      CNPJ
                    </Typography>
                    <Typography variant="body2" className={classes.textPaper}>
                      {model.cnpj}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    <Typography variant="caption" className={classes.subtitulosPaper}>
                      Funcionário
                    </Typography>
                    <Typography variant="body2" className={classes.textPaper}>
                      {model.usuario}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    <Typography variant="caption" className={classes.subtitulosPaper}>
                      Leitura/Baixa
                    </Typography>
                    <Typography variant="body2" className={classes.textPaper}>
                      {pegaDataHora(new Date(model.dataLeitura))}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Paper>
  );
};
