import { makeUseAxios, Options } from 'axios-hooks';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useEffect } from 'react';
import { merge } from 'lodash';
import LRU from 'lru-cache';
import { BaixasStorageKeys, useBaixasStorage } from '../../../../services/use-cases/baixas-storage';
import { useState } from 'react';
import { ApiBaixasProps } from './api-baixas-props';
import { ApiBaixasResponse, EnumRetornoApiBase } from './api-baixas-response';
import { RetornoApiModel } from '../../../../model/api/retorno-api-model'
type RequestParams = AxiosRequestConfig & {
    enviarTokenUsuario: boolean;
};
const defaultTimeout = 15 * 1000; /* 10 Segundos */
const useAxios = makeUseAxios({
    axios: axios.create({
        baseURL: `https://apisaurusticket.azurewebsites.net/`,
        timeout: defaultTimeout,
        timeoutErrorMessage:
            "O servidor não respondeu a solicitação, tente novamente mais tarde.",
    }),
    cache: new LRU({ max: 15 }),
});
export function useApiBase(props?: ApiBaixasProps) {
    //const { refreshUser, deslogar, converterToken, validaUsuarioConectado } = useSessaoAtual();
    const { getRegister } = useBaixasStorage();
    const { config, opcoes } = props || {};
    const [{ loading: carregandoAxios }, invocarAxios] = useAxios<any>(
        config || "",
        merge({ manual: true }, opcoes)
    );
    const [carregandoInterno, setCarregandoInterno] = useState(false);
    useEffect(() => {
        setCarregandoInterno(carregandoAxios);
    }, [carregandoAxios]);
    const invocarApi = useCallback(async (cfg?: RequestParams, options?: Options, tentarNovamente: boolean = true): Promise<RetornoApiModel> => {
        const objRet = { tipoRetorno: EnumRetornoApiBase.Servidor, statusCode: 0 };
        let resultado: AxiosResponse<any> | undefined;
        let erro: Error | undefined;
        resultado = undefined;
        erro = undefined;
        const tokenStorage = getRegister(BaixasStorageKeys.AuthToken, false);
        //const userToken = converterToken(tokenStorage);
        try {
            const tokenEnvio = cfg?.enviarTokenUsuario
                ? `Bearer ${tokenStorage}`
                : '';
            resultado = await invocarAxios(
                {
                    ...cfg,
                    headers: {
                        Authorization: tokenEnvio,
                        ...cfg?.headers,
                    },
                },
                options
            );

            const { tipoRetorno: tpretorno, statusCode: retStatus } = ApiBaixasResponse(undefined, resultado);
            objRet.statusCode = retStatus;
            objRet.tipoRetorno = tpretorno;

        } catch (e: any) {
            erro = e;
            const { tipoRetorno: tpretorno, statusCode: retStatus } = ApiBaixasResponse(e, e.response);
            objRet.statusCode = retStatus;
            objRet.tipoRetorno = tpretorno;
            if (e.response && e.response?.data?.title) {
                if (e.response?.data?.body) {
                    erro = e.response?.data
                }
                else {
                    if (e.response?.data?.errors) {
                        if (e.response?.data?.errors?.Messages.length > 1) {
                            const erroFormatado = e.response?.data?.errors?.Messages.join('. ');
                            erro = new Error(erroFormatado);
                        } else {
                            erro = new Error(e.response?.data?.errors?.Messages[0]);
                        }
                    }
                    else {
                        erro = new Error(e.response?.data?.title);
                    }
                }
            }

            switch (objRet.tipoRetorno) {
                case EnumRetornoApiBase.Local:
                    let msg = erro?.message?.toString() || "";
                    if (msg.indexOf("timeout") > -1 && msg.indexOf("exceeded") > -1) {
                        erro = new Error(
                            `O servidor demorou muito para processar a requisição (${(cfg?.timeout || defaultTimeout) / 1000
                            }s).`
                        );
                    } else {
                        erro = new Error(
                            `A comunicação com o servidor foi perdida. Tente novamente em alguns instantes.` +
                            (msg.length > 0 ? `Detalhe: ${msg}` : ``)
                        );
                    }
                    break;
                case EnumRetornoApiBase.Api:
                    if (retStatus === 401) {
                        erro = new Error(
                            `Sua sessão expirou. Realize o login novamente para continuar. (401) `
                        );

                        /*
                        if (cfg?.enviarTokenUsuario) {
                          deslogar(userToken?.usuarioId || '');
                          history.push('/login/expirou');
                        }
                        */

                    }
                    break;
                case EnumRetornoApiBase.Servidor:
                    erro = new Error(
                        `O procedimento solicitado causou um erro no servidor. Tente novamente em alguns instantes. Detalhe: ${erro?.message}`
                    );
                    break;
            }
        }
        setCarregandoInterno(false);

        return {
            resultado,
            erro,
            tipoRetorno: objRet.tipoRetorno,
            statusCode: objRet.statusCode,
        };
    },
        [getRegister, invocarAxios]
    );

    return {
        invocarApi,
        carregando: carregandoInterno,
    };
}