import { HistoricoCard } from "../components/historico-card/historico-card";
import { HistoricoModel } from "../../../../model/api/Historico/historico-model";
import { Typography } from "@material-ui/core";

export interface HistoricoGridProps {
    list: Array<HistoricoModel>;
    carregando: boolean;
}

export const HistoricoListData = ((props: HistoricoGridProps) => {

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <Typography variant="h5" style={{textAlign: 'center', margin: '1rem 0'}}>
                    Nenhuma leitura encontrada.
                </Typography>
            )}
            {props.list.length > 0 && props.list.map((historico, index) => {
                return (
                    <HistoricoCard
                        model={historico}
                        key={index}
                    />
                );
            })}

        </>
    );
}
);
