import React from 'react';
import { RouterApp } from './routes/router-app'
import './assets/reset.css'

function App() {
  return (
      <RouterApp />
  );
}
export default App;
