import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  textFieldSaurus: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "inherit",
    },
  },
  disabled: {
    color: theme.palette.text.disabled
  },
  textFieldSaurusCor: {
    "&::before": {
      borderBottom: '2px solid #DCDCDC'
    },
    "&::after": {
      borderBottom: '2px solid #F68D31'
    },
  },
}));
