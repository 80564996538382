import { Button, Grid, Grow } from "@material-ui/core"
import { useHistory } from "react-router";
import { useStyles } from "./first-access-page-styles";
export const FirstAccessPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const redirectSenha = () => {
        history.push("/escolhersenha");
    }
    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <Grow in={true}>
                    <Grid item xs={12}>
                        <div><div className={classes.title}>Primeiro Acesso :)</div></div>
                    </Grid>
                </Grow>
                <Grow in={true}>
                    <Grid item xs={12} className={classes.text}>
                        Verificamos que este é o seu primeiro acesso no Saurus Tickets.
                    </Grid>
                </Grow>
                <Grow in={true}>
                    <Grid item xs={12} className={classes.text}>
                        Para continuar, você deve nos fornecer algumas informações de acesso.
                    </Grid>
                </Grow>
                <Grow in={true}>
                    <Grid item xs={12} className={classes.buttonWrapper}>
                        <Button
                            className={classes.button}
                            onClick={() => redirectSenha()}
                            variant={"contained"}
                            fullWidth
                        >
                            Iniciar Configuração
                        </Button>
                    </Grid>
                </Grow>
            </div>
        </div>
    )
}