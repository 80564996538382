import { createStyles, makeStyles } from "@material-ui/core"
import Background from '../../../assets/img/Background.svg'
export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            background: `url(${Background})`,
            height: '100%',
            width: '100%',
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 1.1rem',
        },
        containerLogin: {
            backgroundColor: '#FEFEFE',
            boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.07)',
            padding: '20px',
            borderRadius: '5px'
        },
        input: {

        },
        toast: {
            width: '100%',
            padding: '25px',
            marginTop: '10px',
            textAlign: 'center'
        },
        forgot: {
            color: '#F68D31',
            fontWeight: 600,
            cursor: 'pointer'
        },
        forgotBox: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '15px',
        },
        title: {
            color: '#F68D31',
            fontWeight: 700,
            fontSize: '30px',
            marginBottom: '30px'
        },
        containerLoginWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        svgImage: {
            [theme.breakpoints.up('sm')]: {
                maxWidth: 400,
            },
            [theme.breakpoints.down('xs')]: {
                maxWidth: 280,
            },
            marginTop: '30px',
            marginBottom: '30px',
        },
        box: {
            "&::before": {
                borderBottom: '2px solid #DCDCDC'
            },
            "&::after": {
                borderBottom: '2px solid #F68D31'
            },
        },
        field: {
            paddingLeft: '10px',
            color: '#000',
        },
        imageWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
        },
        text: {
            color: theme.palette.grey[500],
            marginTop: '15px',
            fontSize: '14px',
            fontWeight: 600,
            '& span': {
                color: '#F68D31'
            }
        },
        wrapper: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        },
        button: {
            backgroundColor: '#F68D31',
            marginTop: '25px',
            textTransform: 'capitalize',
            color: 'white',
            fontWeight: 700,
            boxShadow: '0.964912px 2.82121px 5.78947px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            "&:hover": {
                backgroundColor: '#E89247'
            }
        },
        endIcon: {
            color: '#DADADA'
        },
        startIcon: {
            color: '#F68D31',
            fontSize: '35px',
            width: '25px',
            fontWeight: 700,
            padding: '4px 0'
        }
    })
)