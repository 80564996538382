import { useSessaoAtual } from "../../../services/providers/sessao-atual-provider/sessao-atual-provider";
import { Button, Grid, Grow, IconButton, Input } from "@material-ui/core";
import { RiBriefcaseLine, RiEdit2Fill, RiLock2Line, RiRestartLine } from 'react-icons/ri'
import { toast, ToastContainer } from "react-toastify";
import { RiArrowDropLeftLine } from 'react-icons/ri'
import { useStyles } from "./password-page-styles"
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router";
import { useFormik } from "formik";
import { TextFieldSaurus } from "../../components/text-field-saurus/text-field-saurus";
import { useFormPasswordPageValidation } from './form-password-page-validation'

export const PasswordPage = () => {
    const classes = useStyles();
    const { changeUserPassword, getData: data } = useSessaoAtual();
    const history = useHistory();
    const { FormPasswordPageValidation } = useFormPasswordPageValidation()
    const formik = useFormik({
        initialValues: {
            nomeTerminal: data()?.nomeTerminal || '',
            cnpj: data()?.cnpj || '',
            senha: '',
            confimarSenha: '',
        },
        onSubmit: values => {
            if (values.senha !== values.confimarSenha) {
                toast.error("As senhas não coincidem");
                return;
            }
            else if (values.senha !== '') {
                changePassword(values.nomeTerminal, values.cnpj, values.senha, true);
            } else {
                changePassword(values.nomeTerminal, values.cnpj, values.senha, false);

            }
        },
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: FormPasswordPageValidation,
    })

    const changePassword = async (nomeTerminal: string, cnpj: string, senha: string, mudouSenha: boolean) => {
        await changeUserPassword(nomeTerminal, cnpj, senha, mudouSenha);
        window.location.href = "/"

    }
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={classes.root}>
                <div className={classes.content}>
                    <div className={classes.back}>
                        <IconButton>
                            <RiArrowDropLeftLine className={classes.backIcon} onClick={() => history.goBack()} />
                        </IconButton>
                    </div>
                    <Grid item xs={12} className={classes.title}>
                        <Grow in={true}>
                            <div>Configurações</div>
                        </Grow>
                    </Grid>
                    <Grow in={true}>
                        <Grid item xs={12} className={classes.text}>
                            Informe os campos abaixo para configurar este terminal.
                        </Grid>
                    </Grow>
                    <Grid item xs={12} className={classes.input}>
                        <Grow in={true}>
                            <Input
                                fullWidth
                                startAdornment={<RiEdit2Fill className={classes.startIcon} />}
                                placeholder={"Nome do Terminal"}
                                {...formik.getFieldProps('nomeTerminal')}
                                autoComplete={"off"}
                                className={classes.box}
                                inputProps={{ className: classes.field }} />
                        </Grow>
                    </Grid>
                    <Grid item xs={12} className={classes.input}>
                        <Grow in={true}>
                            <TextFieldSaurus
                                disabled={false}
                                tipo="CNPJ"
                                {...formik.getFieldProps('cnpj')}
                                fullWidth
                                placeholder={"CNPJ"}
                                className={`${classes.field} ${classes.box}`}
                                autoComplete={"off"}
                                startAdornment={<RiBriefcaseLine className={classes.startIcon} />}
                                helperText={formik.touched.cnpj && formik.errors.cnpj ? formik.errors.cnpj : undefined}
                                error={Boolean(formik.touched.cnpj && formik.errors.cnpj)}
                            />
                        </Grow>
                    </Grid>
                    <Grid item xs={12} className={classes.input}>
                        <Grow in={true}>
                            <Input
                                fullWidth
                                startAdornment={<RiLock2Line className={classes.startIcon} />}
                                placeholder={"Senha"}
                                type="password"
                                {...formik.getFieldProps('senha')}
                                autoComplete={"off"}
                                className={classes.box}
                                inputProps={{ className: classes.field }} />
                        </Grow>
                    </Grid>
                    <Grid item xs={12} className={classes.input}>
                        <Grow in={true}>
                            <Input
                                fullWidth
                                type="password"
                                {...formik.getFieldProps('confimarSenha')}
                                autoComplete={"off"}
                                startAdornment={<RiRestartLine className={classes.startIcon} />}
                                placeholder={"Repetir Senha"}
                                className={classes.box}
                                inputProps={{ className: classes.field }} />
                        </Grow>
                    </Grid>
                    <Grow in={true}>
                        <Grid item xs={12} className={classes.buttonWrapper}>
                            <Button type={"submit"} variant="contained" fullWidth className={classes.button}>Salvar Configurações</Button>
                        </Grid>
                    </Grow>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        className={classes.toast}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </form>
    )
}