import { createStyles, makeStyles } from "@material-ui/core"
import Background from '../../../assets/img/Background.svg';

export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            background: `url(${Background})`,
            height: '100%',
            width: '100%',
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                height: "100vh"
            },
            [theme.breakpoints.down('xs')]: {
                height: "500px",
                marginTop: 50
            },
        },
        nav: {
            position: 'fixed',
            bottom: 0,
            height: '75px',
            width: '100%',
        },
        icon: {
            color: '#F68D31',
            fontSize: '30px'
        },
        label: {
            color: '#8D8D8D',
            fontWeight: 700,
            fontSize: '20px'
        },
        main: {
            maxWidth: '650px',
            height: '100%',
        },
        container: {
            margin: '20px',
            backgroundColor: 'white',
            borderRadius: '12px',
            height: '80%',
            boxShadow: '0px 5px 10px 5px rgba(0, 0, 0, .05)'
        }
    })
)