import { TextField, Box, useTheme } from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import { useStyles } from "./text-field-saurus-styles";
import { TextFieldSaurusProps } from "./text-field-saurus-props";
import { toDecimalString } from "../../../utils/to-decimal";

import {
  useCallback,
  useState,
  useEffect,
  ReactElement,
  FocusEvent,
  useRef,
  ChangeEvent,
} from "react";
import { mask } from "./text-field-saurus-masks";
import { validador } from "./text-field-saurus-validator";
// import {
//   CadeadoIcon,
//   CadeadoSenhaIcon,
//   EmailIcon,
//   OlhoAbertoIcon,
//   OlhoFechadoIcon,
// } from "views/components/icons";
export const TextFieldSaurus = ({
  onBlur,
  onChange,
  onSearch,
  value,
  adornmentColor,
  searchable,
  showStartAdornment,
  allowSubmit,
  tipo,
  casasDecimais,
  min,
  max,
  step,
  showPasswordVisibilityButton,
  manterMascara,
  readOnly,
  startAdornment,
  ...txtProps
}: TextFieldSaurusProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [type, setType] = useState("");
  const [endAdornment, setEndAdornment] = useState<ReactElement | null>(null);
  // const [startAdornment, setStartAdornment] = useState<ReactElement | null>(null);
  const [invalido, setInvalido] = useState(false);
  const refValue = useRef("");

  const putMask = useCallback(
    (value: string) => {
      //CORRECAO DE QUANDO VEM EXTERNO PARA PREENCHIMENTO (DE DECIMAL)
      if (typeof value === "number")
        value = toDecimalString(value, tipo === "DECIMAL" ? casasDecimais : 0);

      return mask(value, tipo, casasDecimais, min, max);
    },
    [tipo, casasDecimais, min, max]
  );
  const validateField = useCallback(
    (value: string) => {
      return validador(value, tipo);
    },
    [tipo]
  );

  const onSearchWrapper = useCallback(() => {
    if (onSearch !== undefined) {
      onSearch(refValue.current);
    }
  }, [refValue, onSearch]);

  const selectInputType = useCallback(() => {
    switch (tipo) {
      case "NUMERO":
        return "text";
      case "NUMERO_RANGE":
        return "number";
      case "EMAIL":
        return "email";
      case "COR":
        return "color";
      case "PASSWORD":
        return "password";
      case "NUMERO_SLIDER":
        return "range";
      case "DATA":
        return "date";
      case "DATA_HORA":
        return "datetime-local";
      case "HORA":
        return "time";
      default:
        return "text";
    }
  }, [tipo]);

  useEffect(() => {
    //controle do tipo html do input
    let tipo = "text";
    //quando eu falo para mostrar a senha ela não seleciona o tipo do input e dica como text
    setType(tipo);
  }, [tipo, selectInputType]);

  useEffect(() => {
    let endAdornment = null;

    //vejo se está inválido
    if (invalido) {
      return;
    } else {
      //vejo se está desabilitado
      if ((txtProps.disabled || readOnly) && !searchable) {
        return;
      } else {
        //vejo se é pesquisável
        if (searchable) {
          endAdornment = (
            <SearchIcon
              style={{
                color: adornmentColor || theme.palette.text.primary,
                cursor: "pointer",
              }}
              onClick={() => {
                if (!(txtProps.disabled || readOnly)) onSearchWrapper();
              }}
            />
          );
        }
        //vejo se é para mostrar botao de mostrar/ocultar senha
        if (showPasswordVisibilityButton) {
          endAdornment = (
            <Box ml={1}>
              {/* <ButtonIcon
                // icon={
                //   showPassword ? <OlhoFechadoIcon tipo="INPUT" /> : <OlhoAbertoIcon tipo="INPUT" />
                // }
                onClick={() => {
                  setShowpassword((prev) => !prev);
                }}
              /> */}
            </Box>
          );
        }
      }
    }
    setEndAdornment(endAdornment);
  }, [
    txtProps.disabled,
    searchable,
    adornmentColor,
    invalido,
    onSearchWrapper,
    theme.palette.text.primary,
    showPasswordVisibilityButton,
    theme.palette.text.disabled,
    readOnly,
  ]);

  useEffect(() => {
    //para o on search pegar o valor
    if (searchable) refValue.current = value as string;
  }, [searchable, value]);

  //caso queira botar algo no on change ta aqui
  const onChangeWrapper = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (manterMascara) {
        event.currentTarget.value = putMask(event.currentTarget.value);
      }
      if (onChange !== undefined) {
        onChange(event);
      }
    },
    [manterMascara, onChange, putMask]
  );

  const onBlurWrapper = useCallback(
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let valid = validateField(event.currentTarget.value);
      setInvalido(!valid);

      if (onBlur !== undefined) {
        if (manterMascara) event.currentTarget.value = putMask(event.currentTarget.value);
        onBlur(event);
      }
    },
    [validateField, onBlur, putMask, manterMascara]
  );

  const onKeyDownWrapper = useCallback(
    (event: any) => {
      if (event.keyCode === 13) {
        if (searchable) {
          onSearchWrapper();
        } else if (!allowSubmit) {
          const form = event.target.form;
          const focusableElements =
            'a:not([disabled]), button:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
          const index = Array.prototype.indexOf.call(
            form.querySelectorAll(focusableElements),
            event.target
          );
          if (index === form.querySelectorAll(focusableElements)) {
            form.querySelectorAll(focusableElements)[0].focus();
          } else {
            form.querySelectorAll(focusableElements)[index + 1].focus();
          }
        }
        if (!allowSubmit) {
          event.preventDefault();
        }
      }
    },
    [searchable, allowSubmit, onSearchWrapper]
  );

  return (
    <>
      <TextField
        className={classes.textFieldSaurusCor}
        inputProps={{
          min: min,
          max: max,
          step: step,
          readOnly: readOnly,
        }}
        InputProps={{
          className: `${txtProps.className} ${readOnly ? classes.disabled : ""}`,
          value: putMask((value as string) || ""),
          startAdornment: startAdornment,
          endAdornment: endAdornment,
          type: type,
          onChange: onChangeWrapper,
          onBlur: onBlurWrapper,
          onKeyDown: onKeyDownWrapper,
        }}
        {...txtProps}
      />
    </>
  );
};
