import { useSessaoAtual } from '../../../services/providers/sessao-atual-provider/sessao-atual-provider'
import { Redirect, Route, RouteProps } from "react-router-dom";

type PrivateRouteProps = RouteProps & {
    isPrivate: boolean,
    checkIsInactive: boolean
}
export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, isPrivate, checkIsInactive, ...rest }) => {
    const { IsValidSession } = useSessaoAtual();

    const routeComponent = () => (

        isPrivate
            ? IsValidSession()
                ? children
                : <Redirect to="/" />

            : checkIsInactive
                ? !IsValidSession()
                    ? children
                    : <Redirect to="/scan-camera" />
                : children
    )
    return (
        <Route {...rest} render={routeComponent} />
    );
}