import { useStyles } from "./settings-page-styles";
import { Nav } from '../../../views/components/nav'
import { Grid, ListItem, List, ListItemIcon, ListItemText, Grow } from "@material-ui/core";
import { RiKey2Line, RiLogoutCircleLine, RiHistoryLine } from 'react-icons/ri'
import { useHistory } from "react-router";
import { useSessaoAtual } from "../../../services/providers/sessao-atual-provider/sessao-atual-provider";
export const SettingsPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const { Logout } = useSessaoAtual()
    const redirectToChangePassword = () => history.push("/redefinirsenha");
    const redirecionarHistorico = () => history.push("/historico");
    const Leave = async () => {
        await Logout()
        history.push('/')

    }
    return (
        <>
            <div className={classes.root}>
                <div className={classes.content}>
                    <Grid item xs={12} className={classes.main}>
                        <Grow in={true}>
                            <div className={classes.container}>
                                <List>
                                    <ListItem button onClick={() => redirectToChangePassword()}>
                                        <ListItemIcon>
                                            <RiKey2Line className={classes.icon} />
                                        </ListItemIcon>
                                        <ListItemText primary="Configurar Terminal" className={classes.label} />
                                    </ListItem>
                                </List>
                                <List>
                                    <ListItem button onClick={() => redirecionarHistorico()}>
                                        <ListItemIcon>
                                            <RiHistoryLine className={classes.icon} />
                                        </ListItemIcon>
                                        <ListItemText primary="Histórico" className={classes.label} />
                                    </ListItem>
                                </List>
                                <List>
                                    <ListItem button onClick={Leave}>
                                        <ListItemIcon>
                                            <RiLogoutCircleLine className={classes.icon} />
                                        </ListItemIcon>
                                        <ListItemText primary="Sair" className={classes.label} />
                                    </ListItem>
                                </List>
                            </div >
                        </Grow>
                    </Grid>
                </div>
            </div>
            <div className={classes.nav}>
                <Nav active="config" />
            </div>
        </>
    )
}