import { Button, Grid, IconButton, Input, InputAdornment } from "@material-ui/core"
import { useStyles } from "./login-page-styles"
import LoginSvgImage from '../../../assets/img/LoginSvg.svg'
import { RiEyeCloseLine, RiEyeLine, RiLock2Line, RiUserLine } from 'react-icons/ri';
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSessaoAtual } from "../../../services/providers/sessao-atual-provider/sessao-atual-provider";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import { CircularLoading } from "../../components/utils/circular-loading/circular-loading";

export const LoginPage = () => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [carregando, setCarregando] = useState(false)
    const { Login, FirstAccess, getData: data } = useSessaoAtual();
    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            nomeUsuario: data()?.nomeUsuario || '',
            senha: ''
        },
        onSubmit: values => {
            EfetuarLogin(values.nomeUsuario, values.senha)
        }
    })

    useEffect(() => {
        if (FirstAccess()) {
            history.push('/firstaccess')
        }
    }, [FirstAccess, history])

    const EfetuarLogin = useCallback(async (nomeUsuario: string, senha: string) => {
        setCarregando(true)
        try {
            await Login(nomeUsuario, senha)
            window.location.href = "/"
        } catch (e: any) {
            toast.error(e.message);
            setCarregando(false)
        }
    }, [Login])

    const redirectToChangePassword = () => history.push("/escolhersenha");

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container style={{ maxWidth: '650px' }}>
                        <Grid item xs={12} className={classes.imageWrapper}>
                            <img src={LoginSvgImage} alt="Imagem de Login" className={classes.svgImage} />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.containerLogin}>
                                <Grid container className={classes.containerLoginWrapper}>
                                    <Grid item xs={12} className={classes.title}>
                                        Login
                                    </Grid>
                                    <Grid item xs={12} className={classes.input}>
                                        <Input
                                            fullWidth
                                            startAdornment={<RiUserLine className={classes.startIcon} />}
                                            placeholder={"Nome do Usuário"}
                                            {...formik.getFieldProps('nomeUsuario')}
                                            autoComplete={"off"}
                                            className={classes.box}
                                            inputProps={{ className: classes.field }} />

                                    </Grid>
                                    <Grid item xs={12} className={classes.input}>
                                        <Input
                                            type={showPassword ? 'text' : 'password'}
                                            fullWidth
                                            startAdornment={<RiLock2Line className={classes.startIcon} />}
                                            placeholder={"Senha"}
                                            className={classes.box}
                                            {...formik.getFieldProps('senha')}

                                            inputProps={{ className: classes.field }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}>
                                                        {!showPassword ? <RiEyeCloseLine className={classes.endIcon} /> : <RiEyeLine className={classes.endIcon} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />

                                    </Grid>
                                    <Grid item xs={12} className={classes.forgotBox}>
                                        <div onClick={() => redirectToChangePassword()} className={classes.forgot}>Esqueceu a senha?</div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type={"submit"} variant="contained" fullWidth disabled={carregando} className={classes.button}>{carregando ? <CircularLoading tipo="ONAUTOCOMPLETE" /> : "Acessar"}</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    )
}