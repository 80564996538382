import { Grid, Grow, IconButton } from "@material-ui/core";
import { RiArrowDropLeftLine } from 'react-icons/ri'
import { useStyles } from "./historico-page-styles"
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router";
import { HistoricoList } from "./historico-list.tsx/historico-list";

export const HistoricoPage = () => {
    const classes = useStyles();
    const history = useHistory();


    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Grid item xs={12} className={classes.title}>
                    <div>
                        <Grow in={true}>
                            <IconButton style={{ padding: 0, marginBottom: 10 }}>
                                <RiArrowDropLeftLine className={classes.backIcon} onClick={() => history.goBack()} />
                            </IconButton>
                        </Grow>
                        <Grow in={true}>
                            <div>Histórico de leitura</div>
                        </Grow>
                    </div>
                </Grid>

                <Grow in={true}>
                    <Grid item xs={12} className={classes.text}>
                        Os códigos podem ser filtrados por setores logo na opção abaixo.
                    </Grid>
                </Grow>
                <Grid item xs={12} style={{ paddingBottom: '1.2rem' }}>
                    <HistoricoList />
                </Grid>
            </div>
        </div >
    )
}