import { Route } from "react-router"
import { ScanPage } from '../../../views/pages/scan-page'
import { SettingsPage } from '../../../views/pages/settings-page'
import { ResponsePage } from '../../../views/pages/response-page'
import { PasswordPage } from '../../../../src/views/pages/password-page'
import { ScanFisicoPage } from "../../../views/pages/scan-fisico-page"
import { HistoricoPage } from "../../../views/pages/historico-page"

export const RouterMain = () => {
    return (
        <div>
            <Route exact path="/settings" component={SettingsPage} />
            <Route exact path="/scan-camera" component={ScanPage} />
            <Route exact path="/scan-fisico" component={ScanFisicoPage} />
            <Route exact path="/response" component={ResponsePage} />
            <Route exact path="/redefinirsenha" component={PasswordPage} />
            <Route exact path="/historico" component={HistoricoPage} />
        </div>
    )
}